<template>
    <b-row cols-lg="pt-4">
        <b-col cols="4"></b-col>
        <b-col cols="4">
            <b-card
                :title="`⚠️ `+ i18nT(`Module is still in development`)+` 🚧`"
                class="text-center py-2">
                <b-card-text>{{ i18nT(`The functionality for this module is comming soon!`) }}</b-card-text>
                <b-card-text>{{i18nT(`We can't wait to share it with you!`)}}</b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>

export default {
  components: {},
}
</script>

<style>

</style>
